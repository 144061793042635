<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.reserved_goods") }}
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        small
        outlined
        class="mr-1"
        @click="$refs.inputUploadFile.click()"
      >
        <v-icon>mdi-upload</v-icon>
        {{ $t("labels.upload") }}
      </v-btn>
      <input
        type="file"
        ref="inputUploadFile"
        accept=".xlsx"
        @change="onInputFileChange"
        class="d-none"
      />

      <v-btn
        color="success"
        small
        outlined
        class="mr-1"
        @click="create"
        :disabled="items.length < 1"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" small outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 345px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th style="width: 125px" class="text-center">
                {{ $t("labels.phone_number") }}
              </th>
              <th class="text-center">{{ $t("labels.note") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`re_${key}`"
              class="text-center"
            >
              <td>{{ item.phone }}</td>
              <td>{{ item.note }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";

export default {
  name: "ZaloBlackListExcel",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    isLoading: false,
    hasChange: false,
  }),
  watch: {},
  mounted() {},
  methods: {
    async onInputFileChange(e) {
      const mappingFields = {
        "Đ.Thoại": "phone",
        "Ghi chú": "note",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            item[mappingFields[i]] = `${v[i]}`.trim();
          });
          item.status = 1;
          return item;
        });
        this.validateData(items);
      };
      await reader.readAsArrayBuffer(f);
    },

    validateData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
        return false;
      }

      if (items.length > 1000) {
        this.$vToastify.error(this.$t("messages.upload_max_1000_rows"));
        return false;
      }

      const wrongRows = items
        .filter((item) => !item.phone || !this.isPhoneValid(item.phone))
        .map((item, index) => index + 1);
      if (wrongRows && wrongRows.length > 0) {
        this.$vToastify.error(
          this.$t("messages.phone_wrong_rows", { rows: wrongRows.join("; ") })
        );
        return false;
      }

      const duplicatePhoness = [];
      const noDuplicatePhones = [];
      items.forEach((i) => {
        const nd = [...noDuplicatePhones].find((n) => n == i.phone);
        if (!nd) {
          noDuplicatePhones.push(i.phone);
        } else {
          duplicatePhoness.push(i.phone);
        }
      });
      if (duplicatePhoness.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            phones: duplicatePhoness.join("; "),
          })
        );
        return false;
      }

      this.items = [...items];
    },

    closeDialog() {
      if (this.hasChange) {
        this.$emit("refreshData", true);
      }
      this.$emit("cancel", true);
    },

    async create() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-zalo-zns-black-list", {
          items: this.items,
          id_customer_zalo_zns_config: this.item.id,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.hasChange = true;
        this.items = [];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
